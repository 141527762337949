*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
    font-family: var(--font-family);
    text-decoration: none;
    
}

body{
    background: conic-gradient(from 180deg at 50% 50%, #DBC8EB 0deg, rgba(255, 255, 255, 0.398) 141.07deg, rgba(234, 194, 218, 0.58) 208.13deg, rgba(255, 255, 255, 0.25) 330deg, #DBC8EB 360deg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-color: #000; */
    /* overflow: hidden; */
}

a{
    color: #525252;
}

.gradient__bg{
    background: conic-gradient(from 180deg at 50% 50%, #DBC8EB 0deg, rgba(152, 106, 210, 0.398102) 141.07deg, rgba(234, 194, 218, 0.58) 208.13deg, rgba(204, 225, 236, 0.25) 330deg, #DBC8EB 360deg);
  
    /* height: 100vh; */
    background-position:top;
    
}

.gradient__text{
    color: #000;
    
}

.section__margin{
    padding: 4rem 6rem;
}

@media screen and (max-width: 700px) {
   .section_padding {
    padding: 4rem;
   } 

   .section__margin{
    margin: 4rem;
   }

   .gradient__bg{
    
    /* height: 110vh; */
    background-position:bottom;
}
}

@media screen and (max-width: 500px) {
    .section_padding {
     padding: 4rem 2rem;
    } 
 
    .section__margin{
     margin: 4rem 2rem;
    }
 }