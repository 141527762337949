.sec{
   
    display: flex;
   
    justify-content: center;
padding: 20px 0 20px 0;
}

.sec_sub{
    display: block;
    background: rgba(239, 242, 246, 0.4);
    padding: 20px;
 border-radius: 24px;
  text-align: center;
  
  width: 33.1%;
  box-shadow: 0  17px 10px -10px rgba(0, 0, 0, 0.4);
   
}
.re{
    width: 99%;
    display: flex;
    justify-content: space-between;
}



.sec_sub h4{
   font-size: large;
   font-weight: bolder;
   color: #486284;
   font-weight: 700;
   font-style: normal;
   letter-spacing: 0.5px;
   display: flex;
   flex-direction: column;
   line-height: 25px;
}

.sec_sub span{
    font-size: large;
    font-weight: bolder;
    color: #161616;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0.5px;
 }



 @media screen and (max-width: 700px) {
    .sec{
        
         display: block;
         padding: 40px 20px 40px 20px;
         
     }
     .sec_sub{
   border-bottom: none;
        width: 100%;
        margin-top: 15px;
        box-shadow: none;
        box-shadow: 0.1px 0.2px 5px 0px rgba(112, 108, 108, 0.75);
        padding: 5px;
        
    }

    .re{
        width: 99%;
        display: block;
       

    }
}