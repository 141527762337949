.push__header {
    display: flex;
    flex-direction: row;
    
    align-items: center;
    
    /* height: 63vh; */
}

.push__header-content-one {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    padding-left: 50px;
}

.push__header-content-one img{
    
    width: 70%;
}

.push__header-content-two {
    
    width: 40%;
}


.push__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
    
}


.push__header-content-two p {
    width: 529px;
/* height: 176px; */
padding: 50px 0 50px 0;
margin-left: 50px;
    font-family: 'Inter';
font-style: normal;
font-weight: 300;
font-size: 36px;
line-height: 44px;
display: flex;
align-items: flex-end;

color: rgba(0, 0, 0, 0.5);

/* backdrop-filter: blur(2px); */
}

.push__header-content__input {
    width: 100%;
    margin: 2rem 0 1rem;
    padding: 0 0 50px 0;
justify-content: space-evenly;
    display: flex;
    flex-direction: row;
}



.push__header-content__input button {
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    appearance: none;
	border: none;
	/* outline: none; */
	 /* background: rgba(239, 242, 246, 0.4);  */
	text-transform: uppercase;
	letter-spacing: 2px;
	padding: 0.85rem 1rem;
	border-radius: 15px;
    color: rgba(0, 0, 0, 0.5);
    font-family: var(--font-family);
    font-weight: bold;
}

@keyframes shadow-green
{
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.3);
  }
  100% {
    box-shadow: 0 0 0 21px rgba(255, 255, 255, 0);
  }
}
@keyframes shadow-red
{
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.3);
  }
  100% {
    box-shadow: 0 0 0 25px rgba(255, 255,255, 0);
  }
}

.btn-in {box-shadow: 0 20px 20px 7px rgba(0, 0,0, .25);border-radius: 30px;}

.example-1
{
  
  animation: shadow-green 1.2s .1s infinite;
  
}



.btn-inner
{
  
  animation: shadow-red 1.2s .5s infinite;

}




.push__header-content__input button:hover{
    box-shadow: 0  37px 20px -20px rgba(0, 0, 0, 0.2);
    transform: translate(0, -10px)
}

.push__header-content__people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    margin-top: 2rem;
}

.push__header-content__people img {
    width: 181.79px;
    height: 38px;
}

.push__header-content__people p {
    margin: 0 0 0 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: #fff;
    text-align: center;
}

.push__header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.push__header-image img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1050px) {
    .push__header {
        flex-direction: column;
        justify-content: center;
        
    }

    .push__header-content {
        margin: 0 0 3rem;
        width: 100%;
        align-items: flex-start;
    }
}

@media screen and (max-width: 650px) {
    .push__header-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .push__header-content p {
        font-size: 16px;
        line-height: 24px;
    }
    
    .push__header-content__people {
        flex-direction: column;
    }

    .push__header-content__people p {
        margin: 0;
    }

  
}

@media screen and (max-width: 490px) {
    .push__header-content h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .push__header-content p {
        font-size: 14px;
        line-height: 24px;
    }

  
}

.menust{
    display: none;
    width: 100%;
  position: fixed;
   z-index: 999;
    justify-content: center;

   
}
.men{
    display: flex;
    justify-content: space-between;
    width: 95%;
    font-size: 1.5em;
    padding: 1em 2.85em;
   border-radius: 10px;
    background-color: #999898; 
}

.menu__item{
    
    all: unset;
    flex-grow: 1;
    z-index: 100;
    display: flex;
    cursor: pointer;
    position: relative;
    border-radius: 50%;
    align-items: center;
    will-change: transform;
    justify-content: center;
    padding: 0.55em 0 0.85em;
    transition: transform var(--timeOut , var(--duration));
    
}




.menu__item.active {

    transform: translate3d(0, -.8em , 0);

}



.icon{
    
    width: 2.6em;
    height: 2.6em;
    stroke: white;
    fill: transparent;
    stroke-width: 1pt;
    stroke-miterlimit: 10;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 400;
    
}

.menu__item.active .icon {

    animation: strok 1.5s reverse;
    
}

@keyframes strok {

    100% {

        stroke-dashoffset: 400;

    }

}

.menu__border{

    left: 0;
    bottom: 99%;
    width: 10.9em;
    height: 2.4em;
    position: absolute;
    clip-path: url(#menu);
    will-change: transform;
    background-color: #1d1d27;
    transition: transform var(--timeOut , var(--duration));
    
}

.svg-container {

    width: 0;
    height: 0;
}

.proje{
    padding: 0.7rem 1rem;
    color: #fff;
    background: #0199b4;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0  17px 10px -10px rgba(0, 0, 0, 0.4);
}


@media screen and (max-width: 50em) {
    .menust{
        display: flex;
        width: 100%;
      position: fixed;
       z-index: 999;
        justify-content: center;
       
    }
    .men{
        display: flex;
        justify-content: space-between;
        width: 95%;
        font-size: 1.5em;
        padding: 1em 2.85em;
       border-radius: 10px;
        background-color: #999898; 
    }
}
@media screen and (max-width: 70em) {
    .menust{
        display: flex;
        width: 100%;
      position: fixed;
       z-index: 999;
        justify-content: center;
       
    }
    .men{
        display: flex;
        justify-content: space-between;
        width: 95%;
        font-size: 1.5em;
        padding: 1em 2.85em;
       border-radius: 10px;
        background-color: #bbb8b8; 
    }

    .push__header-content-one {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-left: 0px;
    }

    .push__header-content-two {
    /* display: grid; */
padding: 10px;
        width: 100%;
        
    }
    .push__header-content-two p {
        width: 100%;
    height: 206px;
    margin-left: 0px;
        font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 44px;
    display: flex;
    text-align: center;
    align-items: center;
    /* padding: 50px; */
    
    color: rgba(0, 0, 0, 0.5);
    
    /* backdrop-filter: blur(2px); */
    }
    .push__header-content__input {
        width: 100%;
        height: 100px;
        margin: 2rem 0 1rem;
    justify-content: space-evenly;
        display: flex;
        flex-direction: row;
    }
    .push__header-content__input button {
        cursor: pointer;
        /* height: 60px; */
        /* width: 220px; */
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        appearance: none;
        border: none;
        font-weight: bold;
        color: #1d1c1c;
        /* outline: none;
        background: rgba(239, 242, 246, 0.4); */
        text-transform: uppercase;
        letter-spacing: 2px;
        padding: 0.85rem 1rem;
        border-radius: 15px;
    }

    
}