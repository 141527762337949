
.full-form{
    display: flex;
    justify-content: center;
}
.form-layout{
    
    
    background-color: #fff;
    width: 70%;
    border-radius: 20px;
    height: 100vh;
}

.form-inner-layout{
    background-color: #686767;
    width: 100%;
    text-align: center;
    border-radius: 20px;
    color: #fff;
    height: 5vh;
    font-weight: 100;
   display: flex;
   
    
}
.form-inner-layout h3{
    margin: auto;
}

.form-boxes .form-box{
    background-color: #f4f4f4;
    display: grid;
}

.form-boxes .form-box label{
    background-color: #f4f4f4;
    display: grid;
}




.formInput{
    display: flex;
    flex-direction: column;
    width: auto;
}

.formInput input{
    padding:  0 0 15px 0;
    margin: 20px 0px;
  
    /* border-bottom: 1px solid gray; */
    border: none;
    border-bottom: 1px solid rgb(153, 153, 153);
    
}

.formInput input:focus{
    outline: none;
}


.formInput label{
    font-size: 15px;
    color: rgb(82, 81, 81);
}

.formInput span{
    font-size: 12px;
    padding: 3px;
    color: red;
    display: none;
}

.formInput input:invalid[focused="true"]{
    border-bottom: 1px solid red;
}

.formInput input:invalid[focused="true"] ~ span{
    display: block;
}

.inpt-in{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.inpt-in h4, i{
    color: rgb(59, 59, 59);
    font-size: 14px;
}

.project-form h1{
   
   text-align: center;
  }
  
  .project-form .forms{
      background-color: white;
      padding: 0px 60px;
      border-radius: 0 0 10px 10px;
      height: auto;
      display: none;
      flex-direction: column;
      width: 60%;
    
  }

  .project-form .forms.active{
    background-color: white;
    padding: 0px 60px;
    border-radius: 0 0 10px 10px;
    height: auto;
    display: flex;
    flex-direction: column;
    width: 60%;
  
}
  .project-form .npts{
   
    margin: 20px 0px;
  
    /* border-bottom: 1px solid gray; */
    border: none;
   
}
.contact_sec .npts{
   
    margin: 10px 0px;
  
    /* border-bottom: 1px solid gray; */
    border: none;
   
}
  
  .project-form h1{
      color: rgb(56, 56, 56);
      text-align: center;
      margin: 25px 0;
  }
  
  .project-form button{
      width: 100%;
      height: 50px;
      padding: 10px;
      border: none;
      background-color: rebeccapurple;
      color: white;
      border-radius: 5px;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
      margin-top: 15px;
      margin-bottom: 30px;
  }

  .project-form{
    padding: 8px;
    display: none;
  }
  .project-form.active{
    padding: 8px;
    display: initial;
  }

  @media screen and (max-width: 1050px){
    .project-form .forms{
        background-color: white;
        padding: 0px 60px;
        border-radius: 0 0 10px 10px;
        height: auto;
        display: none;
        flex-direction: column;
        width: 90%;
      
    }
  
    .project-form .forms.active{
      background-color: white;
      padding: 0px 60px;
      border-radius: 0 0 10px 10px;
      height: auto;
      display: flex;
      flex-direction: column;
      width: 90%;
    
  }
  }