.push__navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 6rem;
   
}

.push__links{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.push__navbar-links_logo{
    margin-right: 2rem;
    
    }

    .push__navbar-links_logo a{
        text-decoration: none;
        
        }
        .push__navbar-links_logo img{
            width: 60px;
            filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2));
        }

.push__navbar-links_logo p{
    padding: 0.5rem 1rem;
    color: #fff;
    background: #0199b4;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0  17px 10px -10px rgba(0, 0, 0, 0.4);
   
}

.push__navbar-links_logo p{
    padding: 0.5rem 1rem;
    color: #fff;
    background: #0199b4;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0  17px 10px -10px rgba(0, 0, 0, 0.4);
}




.push__navbar-links_containers .rob{
    text-decoration: none;
    color: #404344;
    padding: 0.5rem 1rem;
    /* box-shadow: 0px 4px 4px 0px rgba(168,168,168,1); */
   /* background-color: #f4f4f4; */
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.5s;
    border-radius: 10px;
}
.push__navbar-links_containers .rob:hover{
    box-shadow: 0 0.5em 0.5em -0.4em black;
    transform: translateY(-0.25em);
}



.push__navbar-links_containers .twi{
    text-decoration: none;
    color: #404344;
    padding: 0.5rem 1rem;

   
    border-radius: 5px;
    padding: 4px 8px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
   transition: 0.5s;
    cursor: pointer;
    border-radius: 5px;
    
}
.push__navbar-links_containers .twi:hover{
    box-shadow: 0 0.5em 0.5em -0.4em black;
    transform: translateY(-0.25em);
}





.twi{
    
    border-radius: 5px;
    padding: 2px 5px;
}

.gl{
    color: #0199b4;
}

.push__navbar-links_containers{
    display: flex;
    flex-direction: row;
    
}

.push__navbar-join{
display: flex;
justify-content: flex-end;
align-items: center;
}



.push__navbar-links_containers p,
.push__navbar-join p,
.push__navbar-menu_container p {
font-family: var(--font-family);
font-weight: 500;
font-size: 18px;
line-height: 25px;
text-transform: capitalize;

margin: 0 1rem;
cursor: pointer;
}
.push__navbar-join .fa-search{
    font-size: 18px;
    /* margin-right: 10px; */
    /* padding-right: 10px; */
    /* border-right: 1px solid gray; */
}

.push__navbar-join .srch-bx{
    margin-right: 20px;
    border-right: 1px solid gray;
    padding-right: 5px;
    display: flex;
}


.push__navbar-join .tweet{
    font-size: 25px;
    margin-left: 10px;
    color: #0199b4;
 cursor: pointer;
 filter: drop-shadow(1px 1px 1px gray);
}

.push__navbar-join .srch-btn{
width: 30px;
/* margin-right: 3px; */
/* margin-left: 3px; */
background: linear-gradient(321deg, rgb(235, 56, 235) 0%, rgba(182,46,191,1) 34%, rgb(194, 47, 182) 100%);
border: none;
padding: 5px;
border-radius: 5px;
cursor: pointer;
}
.push__navbar-join .srch-btn i{
    color: #fff;
}

.push__navbar-join input{
    padding: 5px;
    border-radius: 5px;
    border: none;
    margin-right: 4px;
    box-shadow: 1px 1px 1px 1px rgb(196, 177, 189);
    background-color: #f4f4f4;
    position: relative;
    -webkit-animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.push__navbar-join input:focus{
    outline: none;
}
.dropd{
     position: absolute; 
    min-width:  175px;
    max-width: fit-content;
    top: 90px;
    background-color: #fff;
    border-radius: 2px;
    z-index: 999;
}

.dropd ul{
    /* background-color: #fff; */
    padding: 5px 10px;
  
}

.nav-inp{
    display: none;
    width: 0%;
}
.nav-inp.active{
    display: initial;
    width: auto;
}
.dropd li{
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
}
.dropd li:hover{
    background-color: #dbd7d7;
}

@-webkit-keyframes slide-left {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(-4px);
              transform: translateX(-4px);
    }
  }
  @keyframes slide-left {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(-4px);
              transform: translateX(-4px);
    }
  }
  
.nav-inp{
    display: none;
    width: 0%;
}
.nav-inp.active{
    display: initial;
    width: auto;
}
.push__navbar-join .buttonss,
.push__navbar-menu_container button {
    padding: 0.5rem 0.5rem;
    color: #ffffff;
    background: linear-gradient(321deg, rgb(235, 56, 235) 0%, rgba(182,46,191,1) 34%, rgb(194, 47, 182) 100%);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0  17px 10px -15px rgba(0, 0, 0, 0.4);
}
.push__navbar-links_containers .active{
    text-decoration: none;
    color: #151616;
    
    box-shadow: 0  12px 10px -9px rgba(59, 59, 59, 0.4);
}

.push__navbar-menu{
    margin-left: 1rem;

    display: none;
    position: relative;
}

.push__navbar-menu svg{
    cursor: pointer;
}

.push__navbar-menu_container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
   z-index: 999;
    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 30px;
    height: 400px;
    margin-top: 1rem;
    transition: 5s;
    min-width: 260px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);

    -webkit-animation: scale-up-hor-right 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-hor-right 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-hor-right {
    0% {
      -webkit-transform: scaleX(0.4);
              transform: scaleX(0.4);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
    }
  }
  @keyframes scale-up-hor-right {
    0% {
      -webkit-transform: scaleX(0.4);
              transform: scaleX(0.4);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
    }
  }


.push__navbar-menu_container a{
    color: #fff;
    font-size: 30px;
    text-decoration: none;
}


    .push__navbar-menu_container p {
        margin: 1rem 0;
    }

    .push__navbar-menu_container-links-sign{
        display: none;
    }

    @media screen and (max-width: 1050px) {
        .push__navbar-links_containers {
            display: none;
        }
    
        .push__navbar-menu {
            display: flex;
        }
    }


    @media screen and (max-width: 700px) {
        .push__navbar {
            padding: 2rem 4rem;
        }
    }

    @media screen and (max-width: 550px) {
        .push__navbar {
            padding: 2rem;
        }
    
        .push__navbar-join {
            display: none;
        }
    
        .push__navbar-menu_containers {
            top: 20px;
        }
    
        .push__navbar-menu_container-links-sign {
            display: block;
            
        }
        
        .push__navbar-menu_container-links-sign i{
            font-size: 28px;
            margin-right: 10px;
            padding-right: 10px;
            border-right: 1px solid gray;
            color: #fff;
        }
    }

