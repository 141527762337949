.push__footer_logo button{
    padding: 0.5rem 1rem;
    color: #fff;
    background: #0199b4;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0  17px 10px -10px rgba(0, 0, 0, 0.4);
}
.push__footer-links_logo img{
    width: 60px;
    filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2));
}



.footer{
   color: #777;
    width: 100%;
    padding-block: 1.5rem;
   
    font-family: var(--font-family);
    
    align-items: center;
    display: flex;
  justify-content: center;
    
}

.footer_box {
    padding: 15px 0;
   display: flex;
   justify-content: center;
    width: 100%;
}

.head{
    font-weight: 600;
    color: #212529;
    text-decoration: none;
    font-size: 20px;
}

.footer_box_social{
 padding-top: 40px;
    width: 90%;
    border-top: 1px solid rgba(201, 198, 198, 0.733);
    text-align: left;
   display: flex;
   
    height: 100%;
    gap: 3rem;
   
    
}

.footer_social{
    margin-top: 15px;
   
    display: flex;
    gap: 1.3rem;
    font-size: 1.5rem;
    align-items: center;
    color: #777;
    
}

.footer_social a{
    padding: .5rem;
    border-radius: 6px;
    transition: all 0.3s ease-in;
  
    color: #252424;
}
.footer_box_discover{
    width: 100%;
}

.footer_box_discover ul{
    list-style: none;
    text-decoration: none;
    margin-top: 10px;
}
.footer_box_discover ul li{
   
    line-height: 30px;
}


.footer_box_discover a{
    color: #777;
    text-decoration: none;
    font-size: 14px;
    transition: 0.5s;
}

.footer_box_discover a:hover{
    color: #242323;
    text-decoration: none;
    font-size: 14px;
}

.footer_box_help{
    width: 100%;
}


.footer_box_help ul{
    list-style: none;
    text-decoration: none;
    margin-top: 10px;
}

.footer_box_help ul li{
    line-height: 30px;
}



.footer_box_help a{
    color: #777;
    text-decoration: none;
    font-size: 14px;
    transition: 0.5s;
}

.footer_box_help a:hover{
    color: #242323;
    text-decoration: none;
    font-size: 14px;
}

.push__footer_logo{
    width:100%;
   
}


.push__footer_logo p{
 line-height: 20px;
 
    padding:  20px 0;
    font-size: 14px;
}


.footer_s {
    display: flex;
    gap: 1.3rem;
    font-size: 1.5rem;
    
    color: #777;
    margin-top: 15px;
}

.footer_s a{
   
    color: #777;
}
.subscribe p{
    
    padding: 20px 0;
    font-size: 16px;
}

.subscribe_box{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: gray;
    color: #252424;
    border-radius: 60px;
    margin-top: 20px;
    
}
.subscribe_box input{
background-color: transparent;
border: none;
width: 90%;
color: #eeecec;
font-size: 17px;
}
.subscribe_box input:focus{
    outline: none;
    }

.subscribe_box input::placeholder{
    
    color: #fff;
   
}

.subscribe{
    width: 100%;
}

.subscribe i{
    transition: all .2s ease;
    border-radius: 100%;
   
    height: 28px;
    line-height: 28px;
    width: 28px;
    text-align: center;
    color: #333!important;
 
    font-size: 20px;
    
}

.footer_social a:hover .fa-facebook-f{
    background: #3b5a9a !important
}

.footer_social a:hover .fa-instagram{
    background: #f02bae !important
}

.footer_social a:hover .fa-twitter{
    background: #1aa9e1 !important
}

.footer_social a:hover .fa-linkedin-in{
    background: #264497 !important
}



@media screen and (max-width:45em) {
    .footer_box {
        padding: 0px 0;
       display: flex;
       justify-content: center;
        width: 100%;
    }

    .footer_box_social{
        width: 90%;
        grid-template-columns: 1fr 2fr;
        text-align: center;
    }
    .push__footer_logo p{
        line-height: 20px;
        
           padding:  20px 0;
           font-size: 14px;
       }
       .footer_social{
        margin-top: 0px;
        display: flex;
        gap: 1.3rem;
        font-size: 1.5rem;
        align-items: center;
        color: #777;
        justify-content: center;
    }

   

    .footer_box_social{
        padding-top: 0px;
           width: 90%;
           border-top: 1px solid rgba(201, 198, 198, 0.733);
           text-align: left;
          display: block;
          text-align: center;
           
           gap: 3rem;
          
           
       }


    .footer{
        
        height: 500px;
       
        
    }

    .footer_box_discover{
        padding-bottom: 15px;
    }
    .footer_box_help{
        padding-bottom: 15px;
    }
}

@media screen and (max-width:35em) {
    .footer_box_social{
        width: 90%;
        grid-template-columns: 1fr ;
    }

   
}

