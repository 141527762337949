.contact_header{
  padding: 80px 0 60px 0;
  display: flex;
  /* height: 80vh; */
  flex-direction: row;
}

.olx{
  background: conic-gradient(from 180deg at 56.52% 11.42%, #DBC8EB 0deg, rgba(152, 106, 210, 0.398102) 141.07deg, rgba(234, 194, 218, 0.58) 208.13deg, rgba(204, 225, 236, 0.25) 330deg, #DBC8EB 360deg);
}

.contact_header_sub-1{
  width: 60%;
  /* height: 10vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: aqua; */
  padding: 0 80px;
  
}

.contact_para-1{
  /* width: 60%; */
  margin-bottom: 50px;
}

.contact_para-1 h5{
  font-weight: 700;
  font-size: 32px;
  line-height: 59px;
  /* or 270% */
  
  letter-spacing: -0.04em;
  
  /* Dark */
  
  color: #25323D;
}
.contact_para-2 h5{
  font-weight: 600;
font-size: 19px;
line-height: 36px;
/* or 240% */

letter-spacing: 0.3px;
padding-bottom: 0;
color: #141313;
}

.contact_header_sub-1 h1{
  font-weight: 700;
  font-size: 41px;
line-height: 59px;
/* identical to box height, or 170% */

letter-spacing: -0.04em;

/* Dark */

color: #25323D;

}


.contact_header_sub-2{
  width: 40%;
 
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact_header_sub-2 img{
  width: 70%;
  transform: rotate(270deg);
}

.contact_head{
  margin-bottom: 50px;
}

.contact_sec{
  display: flex;
  flex-direction: row;
  display: none;
}
.contact_sec.active{
  display: flex;
  flex-direction: row;
  display: flex;
}

.addpro{
  display: flex;
/* background-color: #141313; */
width: 60%;
height: 65px;
}

.addpro .add{
  background:rgb(255, 255, 255);
  border: none;
  width: 50%;
 border: 1px solid rgb(190, 189, 189);
  color: #000000;
  text-align: center;
}


.addpro .add.active{
  
  background-color: #ac40d6;
  width: 50%;
  
  
  text-align: center;
  color: #fffefe;
}

.pole .lk{
  margin: 10px 0px;
}


.po{
  display: none;
}
.po.active{
  display: none;
}

.contact_sub_sec-1{
  width: 50%;
  display: flex;
    flex-direction: column;
  
}

.contact_sub_sec-2{
  width: 50%;
  display: flex;
  justify-content: center;
}

.contact_but{
 display: flex;
 padding: 0 10px;
 flex-wrap: wrap;

}
.contact_but .ct-bt{
  width: 150.33px;
height: 55px;
margin: 10px 30px;
/* background: #A91079; */
border-radius: 8px;

/* Inside auto layout */
border: none;

order: 0;

font-size: 15px;
line-height: 22px;
/* identical to box height */

text-align: center;
font-size: 17px;
font-weight: 500;
color: #070707;
cursor: pointer;
border: 1px solid gray;
}
.contact_but a .ct-bt{
  width: 150.33px;
height: 55px;
margin: 10px 30px;
/* background: #A91079; */
border-radius: 8px;

/* Inside auto layout */
border: none;

order: 0;

font-size: 15px;
line-height: 22px;
/* identical to box height */

text-align: center;
font-size: 17px;
font-weight: 500;
color: #070707;
cursor: pointer;
border: 1px solid gray;
}
.activ{
  background: #A91079;
 }

.contact_but .ct-bt.active{
  background: #A91079;
border: none;
color: #EEEEEE;
}




        .contact_but button:hover{
          box-shadow: inset -7px -7px 20px 0 rgba(110, 110, 110, 0.7),
inset 7px 7px 20px 0 rgba(88, 88, 88, 0.2);
        }
        
        


.contact_left_para{
  padding: 70px 0 70px 70px;
  display: flex;
  align-items: center;
  /* height: 60vh; */
}
.contact_left_para h3{
  font-weight: 700;
font-size: 48px;
line-height: 72px;

color: #000347;
}
.tabular{
  /* height: 25vh; */
  padding: 30px 0 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
 
}

.tabular .cts{
  /* background: rgb(96,9,240);
  background: linear-gradient(0deg, rgba(96,9,240,1) 0%, rgba(129,5,240,1) 100%); */
  border: none;
  
  width: 150.33px;
height: 55px;
margin: 10px 30px;
/* background: #A91079; */
border-radius: 8px;

/* Inside auto layout */
border: none;

order: 0;

font-size: 15px;
line-height: 22px;
/* identical to box height */

text-align: center;
font-size: 17px;
font-weight: 500;
color: #ffffff;
cursor: pointer;

}

.tabular .c{
  
  border: none;
  
  width: 150.33px;
height: 55px;
margin: 10px 30px;
/* background: #A91079; */
border-radius: 8px;

/* Inside auto layout */
border: none;

order: 0;

font-size: 15px;
line-height: 22px;
/* identical to box height */

text-align: center;
font-size: 17px;
font-weight: 500;
color: #000000;
cursor: pointer;

}
.tabular .c.active{
  background: rgb(96,9,240);
  background: linear-gradient(0deg, rgba(96,9,240,1) 0%, rgba(129,5,240,1) 100%);
  border: none;
  
  width: 150.33px;
height: 55px;
margin: 10px 30px;
/* background: #A91079; */
border-radius: 8px;

/* Inside auto layout */
border: none;

order: 0;

font-size: 15px;
line-height: 22px;
/* identical to box height */

text-align: center;
font-size: 17px;
font-weight: 500;
color: #ffffff;
cursor: pointer;

}

.form{
  width: 390px;
  display: none;
  background: rgb(255, 255, 255);
  box-shadow: 0.1px 0.2px 5px 0px rgba(192, 192, 192, 0.75);
  /* top: -20px; */
  left: 870px;
  /* transform: translate(0%,-5%); */
  border-radius: 10px;
  padding: 25px;
  /* height: 70vh; */
}
.form.active{
  display: grid;
}
.form h2{
  width: 320px;
  font-family: sans-serif;
  text-align: center;
  color: #242424;
  font-size: 27px;
  /* background: #E5E5E5; */
  border-radius: 10px;
  margin: 2px;
  padding: 8px;
}
.bdt{
  display: flex;
  justify-content: center;
}


.bdt .btnn{
  width: 240px;
  height: 40px;
  background: #A91079;
  border: none;
  margin-top: 30px;
  font-size: 18px;
  border-radius: 60px;
  cursor: pointer;
  color: #1f1f1f;
  transition: 0.4s ease;
 
}
.bdt .btnn {
  width: 240px;
  height: 40px;
  /* background: #201c1f; */
  border: none;
  margin-top: 30px;
  font-size: 16contact_left_parapx;
  border-radius: 60px;
  cursor: pointer;
  color: #ffffff;
  transition: 0.4s ease;
  /* box-shadow: inset -7px -7px 20px 0 rgba(26, 25, 25, 0.7),
  inset 7px 7px 20px 0 rgba(0, 0, 0, 0.2); */
}

.form input{
  width: 340px;
  /* height: 35px; */
  background: transparent;
  /* border-bottom: 1px solid #000000; */
  border-top: none;
  border-right: none;
  border-left: none;
  color: #000;
  font-size: 15px;
  letter-spacing: 1px;
  /* margin-top: 30px; */
  font-family: sans-serif;
}




.form textarea{
  width: 340px;
  height: 35px;
  background: transparent;
  border-bottom: 1px solid #000000;
  border-top: none;
  border-right: none;
  border-left: none;
  color: #000;
  font-size: 15px;
  letter-spacing: 1px;
  margin-top: 30px;
  font-family: sans-serif;
}

.form textarea:focus{
  outline: none;
}

.form input:focus{
  outline: none;
}

::placeholder{
  color: #000;
  font-family: Arial;
}


.btnn:hover{
  background: #555555;
  color: #d1d1d1;
}
.btnn a{
  text-decoration: none;
  color: #535353;
  font-weight: normal;

}

.btnn i{
  text-decoration: none;
  color: #fffdfd;
  font-weight: normal;
  font-size: 15px;
  
}
.form .link{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 17px;
  padding-top: 20px;
  text-align: center;
}
.form .link a{
  text-decoration: none;
  color: #000;
}
.liw{
  padding-top: 15px;
  padding-bottom: 10px;
  text-align: center;
}
.icons a{
  text-decoration: none;
  color: #fff;
}
.icons .ion-icon{
  color: #fff;
  font-size: 30px;
  padding-left: 14px;
  padding-top: 5px;
  transition: 0.3s ease;
}
.icons ion-icon:hover{
  color: #ff7200;
}

.cont-bot{
  padding: 0 70px;
}

.cont-bot h1{
  font-weight: 700;
font-size: 30px;
line-height: 42px;

color: #000347;
}
.cont-bot p{
  font-weight: 700;
font-size: 15px;


color: #3b3b3b;
}

@media screen and (max-width: 1050px){

  .addpro{
    display: flex;
  /* background-color: #141313; */
  width: 90%;
  height: 65px;
  }
  
  .addpro .add{
    background:rgb(255, 255, 255);
    border: none;
    width: 50%;
   border: 1px solid rgb(190, 189, 189);
    color: #000000;
    text-align: center;
    margin: 5px;
  }
  .contact_header{
    padding: 80px 0 60px 0;
    display: flex;
    /* height: 80vh; */
    flex-direction: column;
  }

  .contact_header_sub-1{
    width: 100%;
    /* height: 10vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: aqua; */
    padding: 0 20px;
    
  }

  .contact_header_sub-2{
    width: 100%;
   
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contact_header_sub-2 img{
    width: 70%;
    transform: rotate(0deg);
  }
  .contact_para-2 h5{
    font-weight: 600;
  font-size: 19px;
  line-height: 36px;
  /* or 240% */
  
  letter-spacing: 0.3px;
  padding-bottom: 50px;
  color: #141313;
  }
  .contact_sec{
    display: flex;
    flex-direction: column;
    display: none;
  }
  .contact_sec.active{
    flex-direction: column;
    display: flex;
  }

  .contact_sub_sec-1{
    width: 100%;
    display: flex;
      flex-direction: column-reverse;
    
  }
  .contact_sub_sec-2{
    width: 100%;
    display: flex;
    justify-content: center;
    padding:  0 0;
  }


  .contact_but .ct-bt{
    width: 140.33px;
  height: 55px;
  margin: 10px 3px;
  /* background: #A91079; */
  border-radius: 8px;
  
  /* Inside auto layout */
  border: none;
  
  order: 0;
  
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */
  
  text-align: center;
  font-size: 17px;
  font-weight: 500;
  color: #070707;
  cursor: pointer;
  border: 1px solid gray;
  }

  .contact_but{
    display: flex;
    padding: 0 10px;
    flex-wrap: wrap;
   }

   .contact_sub_sec-2{
    width: 100%;
    display: flex;
    justify-content: center;
    padding:  50px 0;
  }

  .cont-bot{
    padding: 0 5px;
  }
  
  .cont-bot h1{
    font-weight: 700;
  font-size: 30px;
  line-height: 42px;
  
  color: #000347;
  }
  .cont-bot p{
    font-weight: 700;
  font-size: 15px;
  
  
  color: #3b3b3b;
  }
}