.project{
    margin-top: 100px;
    text-align: center;
    /* height: 60vh; */
    
}

.cen{
    display: flex;
    justify-content: center;
    margin: auto;
align-items: center;
   
    
}



.bg-card{
    display: flex;
}

.project i{
  color: #0199b4;
  box-shadow: 0  17px 10px -10px rgba(0, 0, 0, 0.4);
}


.pp{
    display: flex;
    justify-content: center;
    margin-top: 40px;

}


.dd{
    width: 85%;
  
    padding: 10px;
    
}
.dd img{
    width: 100%;
    border-radius: 5%;
}
.dar {
    transition: 0.3s;
    cursor: pointer;
    width: 150px;
}

.dar img{
    border-top-right-radius: 40%;
    transition: 0.3s;
    height: 150px;
   /* width: 50%; */
}
.dar:hover img{
    border-top-right-radius: 30%;
    /* width: 50%; */

}
.dar:hover {
    transform: translate(0, -10px)
}

.dd img{
    width: 100%;
    border-radius: 5%;
   
}