.push__cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    
    padding: 2rem;
    width: 90%;
   
   
    
}
.push__full{
justify-content: center;
display: flex;
}
.push__cta-content {
    display: flex;
    flex-direction: column;

    text-align: left;
    color: #fff;
}

.push__cta-content p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 30px;
    font-weight: 500;
    color: #000;
}

.push__cta-content h3 {
    font-family: var(--font-family);
    font-size: 24px;
    line-height: 45px;
    font-weight: 800;
    color: #222529;
}

.push__cta-btn {
    display: flex;
   justify-content: center;
    align-items: center;
width: 100%;
    margin-left: 2rem;
}

.push__cta-btn h1{
   color: #535252;
   font-weight: 300;
   font-size: 25px;
}



.sub_box{
    margin-left: 15px;
    width: 55%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 43px;    
    background-color:#f4f4f4;
}
.sub_box input{
background-color: transparent;
margin-left: 10px;
border: none;
width: 90%;
color: #0c0b0b;
font-size: 17px;
}

.sub_box input:focus{
  outline: none;
    }

.sub_box input::placeholder{
    
    color: #555353;
   
}

.butt{
    background: gray;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    padding: 0.4rem 0.4rem;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  width: 60px;
    border: none;
    outline: none;
    cursor: pointer;
    min-width: 10px;
}

@media screen and (max-width: 1050px){
    .sub_box{
        margin-left: 0px;
        width: 55%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 43px;    
        background-color:#f4f4f4;
      
    }
}

@media screen and (max-width: 650px) {
    .push__cta {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        
        padding: 0rem;
        width: 100%;
       
       
        
    }

    .push__cta-btn {
        margin: 2rem 0 0;
        display: block;
    
        
    }
    .sub_box{
        margin-top: 15px;
        width: 100%;
    }

    
}

@media screen and (max-width: 550px) {
    .push__cta {
        flex-direction: column;
        margin: 4rem 2rem;
    }

    .push__cta-content h3 {
        font-size: 18px;
        line-height: 32px;
    }

    .push__cta-btn button {
        font-size: 14px;
        line-height: 28px;
    }
}