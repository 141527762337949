.push_learn_head{
    
    display: grid;
    justify-content: center;
    align-items: center;
}

.push_learn_head h1{
    color: rgb(56, 56, 56);
    text-align: center;
}

.push_learn_button{
 /* height: 15vh; */
    display: flex;
   justify-content: space-evenly;
   align-items: center;
    
}

.push_learn_button button:nth-last-child(3){
    min-width: 150px;
  padding: 15px 10px;
  margin: 20px;
  background: #416dea;
  border: none;
  border-radius: 25px;
  color: rgb(255, 255, 255);
  font-size: 15px;
  font-weight: bold;
  
  
  box-shadow: -7px -7px 20px 0 rgba(255, 255, 255, 0.7),
    7px 7px 20px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.push_learn_button button:nth-last-child(2){
    min-width: 150px;
  padding: 15px 10px;
  margin: 20px;
  background: #019cbe;
  border: none;
  border-radius: 25px;
  color: rgb(255, 255, 255);
  font-size: 15px;
  font-weight: bold;
  
  letter-spacing: 2px;
  box-shadow: -7px -7px 20px 0 rgba(255, 255, 255, 0.7),
    7px 7px 20px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.push_learn_button button:nth-last-child(1){
    min-width: 150px;
  padding: 15px 10px;
  margin: 20px;
  cursor: pointer;
  background: #8c22f0;
  border: none;
  border-radius: 25px;
  color: rgb(255, 255, 255);
  font-size: 15px;
  font-weight: bold;
  
  letter-spacing: 2px;
  box-shadow: -7px -7px 20px 0 rgba(255, 255, 255, 0.7),
    7px 7px 20px 0 rgba(0, 0, 0, 0.2);
}


.push_learn_button button:hover{
    box-shadow: inset -7px -7px 20px 0 rgba(26, 25, 25, 0.7),
    inset 7px 7px 20px 0 rgba(0, 0, 0, 0.2);
}
.push_learn_mid{
    display: flex;
    /* height: 10vh; */
    /* height: 60vh; */
    align-items: center;
}
.push_learn_mid-1{
   width: 60%;
    /* height: 10vh; */
    /* background-color: black; */
display: flex;
cursor: pointer;

}
.mod{
    width: 100%;
}
.mod iframe{
    border-radius: 10px;
}

.push_learn_mid-1 iframe{
    border-radius: 15px; 
    margin-left: 50px;
    box-shadow: 0.1px 0.2px 5px 0px rgba(0,0,0,0.75);
    z-index: -1;
    
 }
.push_learn_mid-2{
    display: block;
  
    width: 35%;
  
}

.push_learn_slider{
    /* height: 50vh; */
    display: flex;
    align-items: center;
}

.learn_vieos h1{
    padding: 10px;
    margin: 50px 50px 0 60px;
    color: rgb(56, 56, 56);
}
.detail-hd{
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: #8c22f0; */
    padding-top: 50px;
}

.detail-hd h1{
    margin: 0px 0px 0px 50px;
    color: rgb(56, 56, 56);
}
.detail-hd h3 {
   
    color: #fff;
    background: #0199b4;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 13px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
 padding: 10px;
    width: 79px;

    text-align: center;
    
 box-shadow: 0  17px 10px -10px rgba(0, 0, 0, 0.4);
 margin-right: 50px;
   transition: 1s;
}

.detail-hd h3:hover{
    box-shadow: 0  37px 10px -20px rgba(0, 0, 0, 0.2);
}

.push_learn_mid-2 h1{
    color: rgb(68, 56, 80);
   margin-bottom: 10px;
}

.push_learn_video iframe{
    border-radius: 20px;
    box-shadow: 0.1px 0.2px 5px 0px rgba(0,0,0,0.75);
   z-index: -1;
}



.learn_grid-2{
    height: 10vh;
}





.learn_grid_vid_cat{
    display: flex;
   
}

.learn_grid_col-1{
    width: 50%;
    display: flex;
    justify-content: center;
    height: 320px;
    align-items: end;
}

.learn_grid_col-1 iframe{
  border-radius: 20px;
  box-shadow: 0.1px 0.2px 5px 0px rgba(0,0,0,0.75);
}

.learn_grid_col-s{
    width: 50%;
    display: flex;
    justify-content: flex-end;
    height: 320px;
    align-items: end;
}

.learn_grid_col-f{
    width: 50%;
    display: flex;
    justify-content: center;
    height: 320px;
    align-items: end;
}
.learn_grid_col-f iframe{
    border-radius: 20px;
    box-shadow: 0.1px 0.2px 5px 0px rgba(0,0,0,0.75);
}
.learn_grid_col-2{
   display: flex;
   
    width: 50%;
}
.learn_grid_right-1{
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: end;
    
    
}

.learn_grid_left-1{
    display: flex;
    width: 50%;
    justify-content: flex-end;
    align-items: end;
   
}
.learn_grid_left-1 iframe{
    border-radius: 20px;
    box-shadow: 0.1px 0.2px 5px 0px rgba(0,0,0,0.75);
    width:400;
        height:580;
}

.learn_grid_right-1 iframe{
    border-radius: 20px;
    box-shadow: 0.1px 0.2px 5px 0px rgba(0,0,0,0.75);
}
.learn_grid_right-2{
    display: grid;
    align-items: end;
    width: 50%;
    justify-content: center;
    
    
}

.learn_grid_left{
display: flex;
justify-content: center;
}

.learn_grid_left iframe{
    border-radius: 20px;
    box-shadow: 0.1px 0.2px 5px 0px rgba(0,0,0,0.75);
}

.learn_grid_left-2{
    display: grid;
   
    width: 50%;
  
    justify-content: center;
}
.learn_grid_left-2 iframe{
    border-radius: 20px;
    margin-top: 40px;
    box-shadow: 0.1px 0.2px 5px 0px rgba(0,0,0,0.75);
}

.learn_grid_right-2 iframe{
    border-radius: 20px;
    box-shadow: 0.1px 0.2px 5px 0px rgba(0,0,0,0.75);
}


@media screen and (max-width: 1050px){
    .learn_grid_right-1 iframe{
        width:400;
        height:280;
    }
}
