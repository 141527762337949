.butn-box{
  width: 70%;
  display: flex;
  justify-content: flex-start;
 height: 80px;
  align-items: center;
}


.full-del{
 /* background: conic-gradient(from 180deg at 56.52% 11.42%, #ebdef5 0deg, rgba(152, 106, 210, 0.398102) 141.07deg, rgba(223, 205, 216, 0.58) 208.13deg, rgba(231, 242, 248, 0.25) 330deg, #e8dbf3 360deg); */
background-color: #fff;

}

  .st-bt{
  
  
    margin-left: 100px;
    margin-top: 7px;
    
  }
.st-bt i{
  color: #fff;
}
  .st-bt:hover{
    background-color: #7f5bff;
    
  }
  .st-btu{
    background: #eaecec;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    border: 1px solid rgb(214, 214, 214);
    outline: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .7);
    cursor: pointer;
  }

  .ban-del{
display: flex;
justify-content: center;

  }
.brsp{
  padding: 0px;
}
  .ban-del .del-img-1{
    
    box-shadow: 0 2px 5px rgba(0, 0, 0, .7);
    height: 310px;
    width: 1260px;
    /* border-radius:10px; */
  }
.in-line{
  display: flex;
  justify-content: space-between;
}
  

  .dip h1{
    text-align: center;
  }

  .dist-btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 80px;
    align-items: center;

  }

  .loc{
    display: flex;
    width: 99%;
    justify-content: space-between;
  }

  .loc h1{
    margin-left: 10px;
  }

  .dist-btn .st-b{
    
    margin-left: 10px;
    margin-top: 7px;
    cursor: pointer;
    transition: 0.2s;
  }
  .dist-btn .st-b:hover{
    
    
      box-shadow:inset .2rem .2rem 1rem  #8abdff, 
             inset -.2rem -.2rem 1rem #5b0eeb;
    
  }
  .diso{
    display: flex;
    /* justify-content: ; */
    /* margin-bottom:40px; */
    justify-content: flex-end;
    padding: 15px;
  }

  .diso .buy-chip{
    background: linear-gradient(to right, #87CEEB, #1E90FF);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);

  }

  .diso .buy-coin{
    position: absolute;
    right: 18px;
    top: 629px;
    background-color: #fff;
    z-index: 999;
    padding: 20px;
    margin-top: 10px;
    border-radius: 20px;
    box-shadow: 1px 2px 3px 1px rgb(199, 198, 198);
    display: none;
    width: 300px;
  }
.error-box{
background: url('../images/datas.png');
  box-shadow: 1px 2px 3px 1px rgb(199, 198, 198);
  border-radius: 20px;
  text-align: center;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 292px 5px;
}
  .buy-coun-con{
    display: flex;
    align-items: start;
    background-color:white;
    margin-bottom: 10px;
    padding: 6px 8px;
    border-radius: 5px;
    cursor: pointer;
    /* transition: all 0.3s ease; */
    transition: 0.2s
  }
  .buy-coun-con h4{
color: #ca8043;
font-size: 13px;
  }

  .buy-coun-con img{
    /* height: 60px; */
    width: 70px;
    border-radius: 5px;
  }
  .diso .buy-coin.active{
    
display: initial;
  }

  .diso .buy-coin a{
    line-break: auto;
/* word-break: break-all;   */  
 } 

 .diso .buy-coin a p{
  /* line-break: auto; */
word-break: break-all;  
text-decoration: underline;  
} 

  .diso .buy-coin .buy-coin-card{
   margin-left: 10px;
  }

  .diso .buy-coun-con:hover{
    background-color: #ffefef;
    border-radius: 6px;
    transform: scale(1.1);
  box-shadow: 0 0 10px rgba(121, 120, 120, 0.3);
  }
  .diso i{
   color: #000000;
  }

  .diso .st-{
    cursor: pointer;
  }
  .diso  .bbt{
    margin-left: 10px;
    cursor: pointer;
  }
  .diso  .bbt:hover{
    background-color: #424141;
    color: #fff;
  }

  .dist-btn button{
   margin: 5px;
  }

  .del-para{
    padding-top: 10px;
    /* padding-bottom: 30px; */
    /* margin-left: 65px; */
    width:auto;
    padding: 15px;
  }

  .del-para p{
    font-size: 15px;
    color:rgb(66, 66, 66);
    word-spacing: 6px;
    line-height: 23px;
    padding: 10px;
    text-rendering: optimizeLegibility;
    font-family: '-apple-system','system-ui','BlinkMacSystemFont','Segoe UI,Roboto','Helvetica Neue','sans-serif';
  }

  .graph-box{
    
    padding-bottom: 50px;
    display: flex;
    justify-content: space-around;
    /* height: 100vh; */
    align-items: start;
    flex-direction: row;
  }

  .graphh{
    width: 70%;
  }

  .token-bx{
    /* height: 430px; */
    background-color: #ffff;
    border-radius: 10px;
    color: #333333;
    width: 27%;
    box-shadow: var(--shadow);
    padding: 20px 5px;
    
  }

  .mix{
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  .token-bx-head{
    color: #333333;
    font-size: 10px;
    margin-left: 10px;
   
    text-align: center;
  }
  .token-bx-head h1{
    
    
    padding-top: 10px;
    text-align: center;
  }

  .token-bx-sub-head{
    height: 50px;
    display: flex;
    width: 268px;
    justify-content:end;
    padding-right: 15px;
    align-items: center;
  }

  .token-bx-btn{
    padding:13px 16px;
    display: flex;
    justify-content: flex-start;
  }
  .kilos{
    font-weight: bolder;
    color: #161515;
    font-size: 14px;
  }
  
  

  .push_status_right .push_but_1{
    width: 25px;
  }
  
  .push_status_right .push_but_2{
    width: 20px;
    margin-left: 10px;
  }
  
  .push_status_right .push_but_3{
    width: 30px;
   margin-left: 10px;
  }

  .push_status_left{
    width: 140px;
   }

   .push_status_right{
 
      font-size: 12px;
      word-break: break-all;
      
      border-radius: 5px;
   }

   .push_right_chain{
    width: 153px;
    word-break: break-all;
      user-select: all;
      background-color: #e2dede62;
      padding: 4px;
      border-radius: 7px;
      
      font-size: 10.5px;
      color: #7c7c7c;
   }

   .push_right_chain_title{
    color: #525252;
    font-weight: bolder;
   }
 
.kilost{
  width: 190px;
}
  .token-bx-btn p{
    font-weight: 500;
  }
.bg-white{
  background-color: #ffffff;
}

  .token-bx-btn button{
 
    /* border-radius: 50px; */
    padding: 0.3rem 1rem;
   
    outline: none;
    /* box-shadow: 0  17px 10px -15px rgba(0, 0, 0, 0.4); */
    box-shadow: 0  37px 10px -15px rgba(0, 0, 0, 0.4);
    cursor: pointer;
  }

  .token-btn-1{
    background: #ffffff;
    border: 1px solid rgb(85, 17, 163);
    box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
    7px 7px 20px 0px rgba(0,0,0,.1),
    4px 4px 5px 0px rgba(0,0,0,.1);
  }

  .token-btn-2, .token-btn-6{
    background: #ffffff;
    border: 1px solid rgb(17, 163, 107);
    box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
    7px 7px 20px 0px rgba(0,0,0,.1),
    4px 4px 5px 0px rgba(0,0,0,.1);

  }

  .token-btn-3 , .token-btn-4, .token-btn-5{
    background: #ffffff;
    border: 1px solid rgb(163, 17, 143);
    box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
    7px 7px 20px 0px rgba(0,0,0,.1),
    4px 4px 5px 0px rgba(0,0,0,.1);
  }
 
 .graph-shd{
  box-shadow: var(--shadow);
  border-radius: 5px;
 
/* width:500x;
height: 80vh; */
background-color: white;

  
 }
 


  
  .investors-head{
    height: 100px;
    display: flex;
    align-items: center;
justify-content: center;
  }

  .investors-head h1{
    
    text-align: center;
  }

  .investors-pics{
    display: flex;
    justify-content: center;
   
    
  }

  .investors-pics div{
    display: flex;
    justify-content: space-around;
    width: 50%;
    flex-wrap: wrap;
    
    height: 120px;
  }

  .investors-pics img{
width: 100px;
height: 100px;
border-radius: 60px;
border: 2px solid rgb(167, 164, 164);
  }

  .star{
    /* height: 200px; */
    display: flex;
    align-items: center;
    /* justify-content: ; */
    width: 40%;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px rgba(99, 99, 99, 0.75);
  }

  .star .star-box{
   /* margin-left: 50px; */
   /* background-color: #f3f3f3; */
    border-radius: 10px;
   display: flex;
    /* box-shadow: 0px 0px 3px 0px rgba(160, 160, 160, 0.75); */
    justify-content: center;
    padding-bottom: 20px;
    /* width: 20%; */
    /* width: ; */
  }

  .star .star-box h3{
   padding: 5px;
   color: rgb(5, 5, 59);
   }

   .inner-{
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    /* width: 85%; */;
    /* border-bottom: 1px solid rgba(175, 177, 255, 0.466); */
    /* align-self: center; */
   }

   .star .star-box .str-l{
  
   
   margin-left: 5px;
    border-radius: 50px;
   width: 60px;
   height: 25px;
   justify-content: space-evenly;
   display: flex;
   align-items: center;
    outline: none;
    /* box-shadow: 0  17px 10px -15px rgba(0, 0, 0, 0.4); */
    box-shadow: 0  37px 10px -15px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    background: #cfcfcf69;
    border: 1px solid rgb(240, 240, 240);
    }

    .star-box .defi{
      font-weight: 600;
    }
    .frst-white{
      padding-top: 50px;
      padding-bottom: 50px;
      display: flex;
      justify-content: space-around;
      /* height: 100vh; */
      align-items: start;
flex-direction: row;
    }
    .frst-white-sub{
      width: 70%;
      border-radius: 10px;
     background-color: white;
     box-shadow: var(--shadow);
     display: flex;
     flex-direction: column;
     min-height: 490px;
     max-height: auto; 
     padding: 50px;
     overflow: hidden;
    }
    .loc img{
      width: 50px;
      border-radius: 60px;
    }
    .tit-img{
      display: flex;
      align-items: center;
    }

    .stat-card{
      background-color: #fff;
      width: 27%;
      box-shadow: var(--shadow);
      border-radius: 10px;
      padding: 33px 35px;
    }

    
    .stat-card-cont{
      display: flex;
      /* justify-content: space-between; */
      align-items: center;
      /* height: 49px; */
text-align: start;
border-bottom: 1px solid rgba(35, 131, 155, 0.212);
padding: 10px 0px;


    }
    .stat-card-conts{
      display: flex;
      /* justify-content: ; */
      align-items: center;
      /* height: 49px; */
      
text-align: start;
/* padding: 5px 13px; */

    }

   

    .readmo{

      border: none;
      color: #0081cc;
      font-weight: bold;
      cursor: pointer;
      transition: 0.5s;
      /* background-color: #4e4e4e3a; */
      background: transparent;
      padding: 5px;
     
      
    }
    .readmo:hover{
      
      border: none;
      color: #001aff;
      font-weight: 500;
      cursor: pointer;
    }

    .h5{
      width: 120px;
      display: flex;
      /* justify-content; */
      color: #3b3939;
      
    }
    .p{
      display: flex;
      width: 45px;
      align-items: center;
      justify-content: space-around;
      font-weight: 600;
      color: #161515;
      font-size: 15px;
    }
    .sst{
      display: flex;
      /* width: 45px; */
      align-items: center;
      
      font-weight: 600;
      color: #161515;
      font-size: 12px;
      
    }
    
    .ssp{
      margin-left: 45px;
      padding:  3px;
      font-size: 12px;
      word-break: break-all;
      user-select: all;
      background-color: #ebebeb8e;
      border-radius: 5px;
      border: 1px solid rgba(172, 172, 172, 0.705);
    }
    .ssph{
      color: #8f8f8f;
      margin-left: 45px;
      padding-bottom: 5px;
      font-size: 13px;
      font-weight: bold;
      border-radius: 5px;
    
    }

    .ssps{
      margin-left: 5px;
     
      font-size: 12px;
      word-break: break-all;
      user-select: all;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
    
    .ssps a{
    
      display: flex;
      align-items: center;
      
    }
    .push_status_right button{
      background-color: transparent;
      color: #001aff;  
      border: none;
      text-decoration: underline;
      
    }
    .push_status_right a i{
      color: #001aff;  
      margin-left: 3px;
      text-decoration: none;
      font-size: 12px;
      cursor: pointer;
    }

   
   .star-box-contant{
    width: 150%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    /* border-bottom: 1px solid rgba(175, 177, 255, 0.466); */
    align-self: center;
   }
   .flex-box{
    width: 100%;
   }

   .wrapp {
    display: flex;
    list-style: none;
    justify-content: center;
  }
  
  .wrapp .icon {
    position: relative;
    background: #c456da42;
    border-radius: 10%;
    padding: 15px;
    margin: 10px;
    width: 70px;
    height: 70px;
    border: 1px solid gray;
    font-size: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  .wrapp .tooltip {
    position: absolute;
    top: 0;
    font-size: 14px;
    background: #ffffff;
    color: #f2ffff;
    padding: 5px 8px;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  .wrapp .tooltip::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background: #ffffff;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  .wrapp .icon:hover .tooltip {
    top: -45px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
  
  .wrapp .icon:hover span,
  .wrapp .icon:hover .tooltip {
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
  }
  
  .wrapp .facebook:hover,
  .wrapp .facebook:hover .tooltip,
  .wrapp .facebook:hover .tooltip::before {
    background: #1877F2;
    color: #ffffff;
  }
  
  .wrapp .twitter:hover,
  .wrapp .twitter:hover .tooltip,
  .wrapp .twitter:hover .tooltip::before {
    background: #1DA1F2;
    color: #ffffff;
  }
  
  .wrapp .instagram:hover,
  .wrapp .instagram:hover .tooltip,
  .wrapp .instagram:hover .tooltip::before {
    background: #E4405F;
    color: #ffffff;
  }
  
  .wrapp .github:hover,
  .wrapp .github:hover .tooltip,
  .wrapp .github:hover .tooltip::before {
    background: #333333;
    color: #ffffff;
  }
  
  .wrapp .youtube:hover,
  .wrapp .youtube:hover .tooltip,
  .wrapp .youtube:hover .tooltip::before {
    background: #CD201F;
    color: #ffffff;
  }
  
  .investor-pro-share h1{
    height: 50px;
    text-align: center;
  }

  .related-projects h1{
    /* margin-top: 50px; */
    height: 50px;
    text-align: center;
  }

  .related-projects-box{
    width: 190px;
    height: 150px;
    margin: 10px;
    text-align: center;
  }
  .related-projects-box img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }

  .related-projects-box p{
    font-weight: boldnp;
  }

  

  .related-cont{
    display: flex;
    height: 300px;
    align-items: center;
    justify-content: center;
   
  }

  .container-invest h1{
    
      color: #000000;
     
      text-align: center;
      padding: 15px;
      
    
  }
  .testim{
    height: 95vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .container-invest {
    /* height: 90vh; */
    width: 80%;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 10px;
    /* box-shadow: 0px 0px 5px 0px rgba(99, 99, 99, 0.75); */
  }
  
  .members {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }

  .swe{
    height: 450px;
  }
  .swipe img{
    width: 100%;
    /* height: 70vh; */
    height: 100%;
  }

  .swipe iframe{
    width: 100%;
    /* height: 70vh; */
    height: 100%;
  }
  
  .member {
    text-align: center;
    flex-basis: 15%;
    padding: 24px;
    box-sizing: border-box;
    margin-bottom: 30px;
  }
  .member img{
    width: 105px;
    height: 102px;
    border: 3px solid #d8d8d8;
    border-radius: 50px;
  }
  
  .member-name {
    margin: 0;
    margin-top: 12px;
    font-weight: 600;
    font-size: 17px;
    color: #007765;
  }
  
  .member-role {
    font-style: italic;
    font-size: 15px;
  }
  

  .middle {

  
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.middle .btn {
  display: inline-block;
  width: 50px;
  height: 50px;
  
  margin: 10px;
  border-radius: 30%;
  box-shadow: 0 5px 15px -5px #00000070;
  color: #3498db;
  overflow: hidden;
  position: relative;
}

.middle .btn-1{
  background: linear-gradient(135deg, rgba(25, 119, 243, 0.6) 0%, rgba(25, 119, 243, 0.2) 50.27%);
/* border: 1px solid #1323af; */
backdrop-filter: blur(6px);
}

.middle .btn-2{
  background: linear-gradient(135deg, rgba(29, 161, 242, 0.6) 0%, rgba(29, 161, 242, 0.2) 50.27%);
backdrop-filter: blur(6px);
}

.middle .btn-3{
  background: linear-gradient(135deg, rgba(39, 135, 245, 0.6) 0%, rgba(39, 135, 245, 0.2) 50.27%);
  backdrop-filter: blur(6px);
}

.middle .btn-4{
  background: radial-gradient(130.55% 130.55% at 13.29% 100.47%, rgba(250, 143, 33, 0.25) 9%, rgba(216, 45, 126, 0.25) 78%);
  backdrop-filter: blur(6px);
}
.middle .btn-5{
  background: radial-gradient(130.55% 130.55% at 13.29% 100.47%, rgba(63, 63, 63, 0.25) 9%, rgba(119, 119, 119, 0.25) 78%);
  backdrop-filter: blur(6px);
}

.middle .btn .fa-facebook-f{
  color: #1323af;
}
.middle .btn .fa-instagram{
  color: #fa1f73;
}
.middle .btn .fa-github{
  color: #4e4e4e;
}

.middle .btn-3 .fa-twitter{
  color: #3498db;
}

.middle .btn i {
  line-height: 50px;
  font-size: 16px;
  transition: 0.2s linear;
}
.dar h4{
  text-align: center;
}
.middle .btn-1:hover .fa-facebook-f{
  transform: scale(1.3);
  color: #1323af;
}

.middle .btn-2:hover .fa-twitter{
  transform: scale(1.3);
  color: #ffffff;
}

.middle .btn-3:hover .fa-linkedin-in{
  transform: scale(1.3);
  color: #ffffff;
}

.middle .btn-4:hover .fa-instagram{
  transform: scale(1.3);
  color: #fa1f73;
}

.middle .btn-5:hover .fa-github{
  transform: scale(1.3);
  color: #4e4e4e;
}

.middle .btn::before {
  content: "";
  position: absolute;
  width: 120%;
  height: 120%;
  background: #3498db;
  transform: rotate(45deg);
  left: -110%;
  top: 90%;
}

.middle .btn:hover::before {
  animation: aaa 0.7s 1;
  top: -10%;
  left: -10%;
}

@keyframes aaa {
  0% {
    left: -110%;
    top: 90%;
  }
  50% {
    left: 10%;
    top: -30%;
  }
  100% {
    top: -10%;
    left: -10%;
  }
}

.project-name{
  width: 30%;
 display: flex;
 flex-direction: column;
 background-color: #fff;
 border-radius: 10px;
 box-shadow: 0px 0px 5px 0px rgba(99, 99, 99, 0.75);
}

.project-name h1{
  color: #000000;
   font-size: 25px;
      
      text-align: center;
}
.klo{
  margin-bottom: 30px;
}

.Related-projects{
  height: 70vh;
  /* display: grid; */
}

.Related-projects h1{
  color: #000000;
     margin: 50px;
      margin-left: 100px;
      text-align: start;
}

@media screen and (max-width: 1335px){
  .frst-white{
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    justify-content: space-between;
    /* height: 100vh; */
    align-items: center;
flex-direction: column;
  }

  .frst-white-sub{
    width: 95%;
    border-radius: 10px;
   background-color: white;
   box-shadow: var(--shadow);
   display: flex;
   flex-direction: column;
   min-height: 490px;
   max-height: auto; 
   padding: 10px;
   overflow: hidden;
   margin-bottom: 20px;
  }



  .stat-card{
    background-color: #fff;
    width: 95%;
    box-shadow: var(--shadow);
    border-radius: 10px;
    padding: 33px 35px;
  }

  
  .stat-card-cont{
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    /* height: 49px; */
text-align: start;
border-bottom: 1px solid rgba(35, 131, 155, 0.212);
padding: 10px 0px;


  }
  .stat-card-conts{
    display: flex;
    /* justify-content: ; */
    align-items: center;
    /* height: 49px; */
    
text-align: start;
/* padding: 5px 13px; */

  }

  
  .graph-box{
    
    padding-bottom: 50px;
    display: flex;
    justify-content: space-around;
    /* height: 100vh; */
    align-items: center;
    flex-direction: column;
  }

  .token-bx{
    /* height: 430px; */
    background-color: #ffff;
    border-radius: 10px;
    color: #333333;
    width: 95%;
    box-shadow: var(--shadow);
    padding: 20px 5px;
    margin-bottom: 20px;
    
  }

  .graphh{
    width: 95%;
    
  }
}


ul {
  padding:0;
  list-style: none;
}
.footer-social-icons {
  width: 350px;
  display:block;
  margin: 0 auto;
}
.social-icon {
  color: #fff;
}
ul.social-icons {
  margin: 10px 0 10px 0;
}
.social-icons li {
  vertical-align: top;
  display: inline;
  height: 100px;
}

.social-icons a {
  color: #fff;
  text-decoration: none;
}
.fa-facebook-f {
  padding:3px 6px;
  border-radius: 60px;
}
.fa-facebook-f:hover {
  background-color: #3d5b99;
}
.fa-twitter {
  padding:3px 4px;
  border-radius: 60px;
}
.fa-twitter:hover {
  background-color: #00aced;
}

.fa-instagram{
  padding:3px 4px;
  border-radius: 60px;
}
.fa-instagram:hover {
  background-color: #eb31bd;
}
.fa-youtube {
  border-radius: 60px;
  padding:3px 4px;
}
.fa-youtube:hover {
  background-color: #e64a41;

}
.fa-linkedin-in {
  padding:3px 4px;
  border-radius: 60px;
}
.fa-linkedin-in:hover {
  background-color: #0073a4;
}
.fa-github {
  padding:3px 4px;
  border-radius: 60px;
}
.fa-github:hover {
  background-color: #5a32a3;
}


.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid rgb(184, 182, 182);
  font-size: 20px;
  text-align: center;
  background-color: white;
 
  color: black;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
  margin-top: -10px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #d32d2d;
}

.makeStyles-container-1 {
  color: #000;
  height: auto;
  border-radius: 20px;
  outline: none;
  padding: 30px;
  position: relative;
  max-width: 568px;
  min-width: 250px;
  background: white;
}





