.full_container{
    background-color: white;
     display: flex;
 }
 .fils{
     width: 25%;
     border-right: 1px solid rgb(241, 241, 241);
     display: flex;
     height: auto;
     justify-content: center;
    
     /* align-items: center; */
     
 }
 
 .filters{
     margin-top: 10px;
     width: 90%;
     
   border-radius: 15px;
   overflow: auto;
    /* overflow: auto; */
    background: rgba(217, 255, 248, 0.274);
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.55);
     -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.55);
     box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
 }
 
 .containi{
     width: 75%;
     color: rgb(211, 211, 211);
 }
 
 .filter_head{
     display: flex;
     align-items: center;
     justify-content: space-between;
     background-color: aliceblue;
    /* box-shadow: 0px 3px 1px 0px rgb(218, 218, 218); */
     height: 50px;
     padding: 10px;
 }
 
 .filter_head h1{
     font-size: 21px;
     color: #000000;
 
 }
 
 .filter_head button{
     display:inline-block;
     text-align:center;
     padding:5px 5px;
     
    color: #252525;
     font-size:11px;
     /* background: linear-gradient(321deg, rgba(148, 148, 148, 0.768) 0%, rgba(97, 97, 97, 0.936) 35%, rgba(95, 95, 95, 0.76) 100%); */
     border-radius:5px 5px 5px 5px;
     box-shadow:0 1px 2px rgba(0,0,0,.3);
     cursor:pointer;
     border: none;
     background-color: #fff;
     transition: 0.2s;
 }
 
 .filter_head button:hover{
     background-color: #fff;
 }
 
 .filter_head button:hover{
     display:inline-block;
     text-align:center;
     padding:5px 5px;
     background-color: #ececec7e;
    color: #252525;
     font-size:11px;
     /* background: linear-gradient(321deg, rgba(148, 148, 148, 0.768) 0%, rgba(97, 97, 97, 0.936) 35%, rgba(95, 95, 95, 0.76) 100%); */
     border-radius:5px 5px 5px 5px;
     box-shadow:0 1px 2px rgba(0,0,0,.3);
     cursor:pointer;
     border: none;
 }
 
 .container_head{
     display: flex;
     align-items: center;
     justify-content:space-between;
     background-color: #fff;
     height: 60px;
     border-bottom: 0.1px solid rgb(221, 220, 220);
 }
 
 .container_head h5{
    
  font-size: 15px;
 }
 .side-b{
     display: none;
 }
 .hs{
     width: 80px;
     height: 30px;
     color: #6b6b6b;
     border-radius: 5px;
     padding: 10px 25px#645074;
    
     font-weight: 400;
     background: transparent;
     cursor: pointer;
     transition: all 0.3s ease;
    
    justify-content: center;
   display: flex;
   align-items: center;
      box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
      7px 7px 20px 0px rgba(0,0,0,.1),
      4px 4px 5px 0px rgba(0,0,0,.1);
     outline: none;
     
 }
 .container_head .grid i{
    
     font-size: 15px;
     color: #272626;
     cursor: pointer;
 
 }
 
 .container_head .grid img{
    
     width: 15px;
 
 }
 
 .container_head select{
     padding: 5px;
     border-radius: 5px;
     border: none;
     margin-left: 12px;
     background-color: #f4f4f4;
     box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
      7px 7px 20px 0px rgba(0,0,0,.1),
      4px 4px 5px 0px rgba(0,0,0,.1);
 }
 .container_head select:focus{
     outline: none;
 }
 
 .container_head i{
     display: flex;
     align-items: center;
     justify-content:flex-end;
     background-color: rgb(194, 193, 193);
     height: 10px;
     color: #000;
     
 }
 
 .sort{
     justify-content: center;
     width: 300px;
     display: flex;
 }

 .sort h5{
    display: initial;
}
 .grid{
     width: 8%;
     justify-content: center;
     display: flex;
 }
 
 .grid i{
   background-color: transparent;
 }
 
 .grid div{
     margin: 5px;
     width: 150px;
     height: 30px;
     color: #6b6b6b;
     border-radius: 5px;
     padding: 10px 25px#645074;
     background-color: transparent;
     font-weight: 400;
     
     cursor: pointer;
     transition: all 0.3s ease;
    
    justify-content: center;
   display: flex;
   align-items: center;
      box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
      7px 7px 20px 0px rgba(0,0,0,.1),
      4px 4px 5px 0px rgba(0,0,0,.1);
     outline: none;
 }
 
 .actit{
     background-color: #6b6b6b;
 }
 
 .catie{
     display: flex;
     justify-content: space-between;
     padding:  0 7px;
     height: 40px;
     align-items: center;
     background-color: #ffffff;
     color: #01428d;
     /* margin-top: 10px; */
     /* border-radius: 15px; */
     /* box-shadow: 1px 2px 1px rgb(231, 231, 231); */
 }
 
 .catie p{
     /* display: block; */
  
   padding: 2px;
   /* line-height: 50px; */
   /* margin: 3em auto; */
   text-align: center;
   color: #FFF;
  
   font-size: 13px;
   /* font-weight: bold; */
   /* text-transform: uppercase; */
   border-radius: 50em;
   /* background: #35a785; */
   box-shadow: 0 3px 0 rgba(0, 0, 0, 0.07);
 }
 
 .catie p i{
     background-color: rgb(255, 255, 255);
     border-radius: 36px;
     padding: 3px;
     color: black;
     cursor: pointer;
     box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
     7px 7px 20px 0px rgba(0,0,0,.1),
     4px 4px 5px 0px rgba(0,0,0,.1);
  }
 
 .cati_sub{
     justify-content: center;
     display: flex;
     width: 100%;
     height: auto;
     padding: 20px 0px;
     overflow: hidden;
     border-bottom: 1px solid rgba(196, 196, 196, 0.363);
     background: aliceblue;
 }
 
 .cati_sub .buts{
     display: flex;
     width: 90%;
     flex-wrap: wrap;
     justify-content: flex-start;
     gap: 5px;
     align-items: center;
 
 }
 
 .cati_sub .buts button{
    
 
    gap: 15px;
     color: rgb(48, 47, 47);
     
     padding: 3px;
   padding: 4px 5px;
     border: 1px solid rgb(209, 209, 209);
     border-radius: 36px;
     padding: 10px 25px#645074;
     
     font-weight:500;
     font-size: 12px;
     background: #fff;
     cursor: pointer;
     transition: all 0.3s ease;
    
    justify-content: center;
   display: flex;
   align-items: center;
      
     outline: none;
 }
 
 .cati_sub .buts button:hover{
     background-color: #f4f4f4;
 }
 .cati_sub .wish{
     display: flex;
     width: 100%;
     flex-wrap: wrap;
     justify-content: flex-start;
     gap: 5px;
     align-items: center;
 overflow: initial;
 }
 
 .cati_sub .wish button{
     gap: 15px;
     color: rgb(48, 47, 47);
     
     padding: 3px;
   padding: 4px 5px;
     border: 1px solid rgb(209, 209, 209);
     border-radius: 36px;
     padding: 10px 25px#645074;
     
     font-weight:500;
     font-size: 12px;
     background: #fff;
     cursor: pointer;
     transition: all 0.3s ease;
    
    justify-content: center;
   display: flex;
   align-items: center;
      
     outline: none;
 }
 
 .cati_sub .wish button:hover{
     background-color: #f4f4f4;
 }
 
 .con{
    padding: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: flex-start;
   /* justify-self: flex-start; */
    /* height: 130vh; */
        /* overflow: auto; */
}

.carda{
   margin:25px;
    width: 200px;
    height: 200px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    box-shadow: 0.1px 0.2px 5px 0px rgba(139, 139, 139, 0.75);
  
}

.card_img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
    
}


.card_body{
   height: 100%;
   width: 100%;
    
   bottom: -52%;
  position: absolute;
    background-color: #1f3d4746;
    backdrop-filter: blur(15px);
    border-radius: 15px;
    padding: 30px;
    color: #000;
   
  
    justify-content: center;
    transition: 1s;
}

.carda:hover .card_body{
bottom: -17%;
}
 
 .class-title{
 text-transform: uppercase;
 font-size: 20px;
 font-weight: 500;
 }
 
 .card-sub-title{
     text-transform: uppercase;
     font-size: 14px;
     font-weight: 300;
 }
 
 .card-info{
     font-size: 16px;
     line-height: 25px;
     margin: 40px 0;
     font-weight: 400;
 }
 
 .card-btn{
     color: #1f3d47;
     background-color: #8fabba;
     padding: 10px 20px;
     border-radius: 5px;
     text-transform: capitalize;
     border: none;
     outline: none;
     font-weight: 500;
     width: 120px;
 }
 
 
 
 .frst_prt-one{
     display: flex;
     justify-content: center;
     align-items: center;
 }
 
 .frst_prt-one h1{
    font-size: 15px;
    color: #fff;
 }
 
 .frst_prt-one img{
     width: 40px;
     border-radius: 50%;
     margin-bottom: 10px;
    
  }
  .frst_prt-two {
     display: flex;
     justify-content: center;
  }
 
  .frst_prt-four{
     display: flex;
     justify-content: space-between;
  }
 
  
 .frst_prt-four .price{
     background-color: #fff;
     padding: 1px 6px;
     border-radius: 15px 0 15px 0 ;
     font-size: small;
 }
 
 .frst_prt-four .marcap{
     
     font-size: small;
 }
  .frst_prt-three p{
     text-align: center;
     font-size: 12px;
     color: #fff;
  }
 
 
  .frst_prt-two h5{
 
 color: #fff;
 
  }
  .live-chip{
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 99;
  }
 
  span.live-icon{
     display: inline-block;
     position: absolute;
     /* top: calc(50% - 5px); */
     background-color: green;
     width: 10px;
     height: 10px;
     /* margin-left: 2px; */
     border: 1px solid rgba(black, .1);
     border-radius: 50%;
     z-index: 1;
     
   }

   
 .marcap{
     color: white;
 }
 
   span.live-icon ::before{
     content: '';
     display: block;
     position: absolute;
     background-color: green;
     width: 100%;
     height: 100%;
     border-radius: 50%;
     animation: live 2s ease-in-out infinite;
     z-index: -1;
   }
 
 .list-co{
     /* display: grid; */
     display: flex;
     flex-direction: column;
 gap: 10px;
 height: 100vh;
 padding: 30px;
 overflow: auto;
 }
 
   .lis{
     display: flex;
     /* margin: auto; */
     /* border-radius: 15px; */
     box-shadow: 0 1px 2px rgba(0, 0, 0, 0.02),
     5px 2px 2px rgba(0, 0, 0, 0.028), 1px 5.1px 5.1px rgba(0, 0, 0, 0.035),
     0 5px 2.1px rgba(0, 0, 0, 0.042), 1px 3.7px 2.8px rgba(0, 0, 0, 0.05),
     0 1px 1px rgba(0, 0, 0, 0.07);
     background-color: #f4f4f4;
     border-radius: 20px;
     /* height: 125px; */
     
   }
   .list-head .price{
     background-color: #ff7b3e;
     padding: 5px 5px;
     border-radius: 15px 0 15px 0 ;
     font-size: small;
     
 }
 
 .list-head h2{
     color: rgb(0, 0, 0);
 }
 
   .list-img{
     width: 15%;
     
     background-color: rgba(35, 35, 54, 1);
     display: flex;
     border-radius: 20px;
   }
   .list-img img{
     width: 55%;
     height: 65%;
     margin: auto;
     border-radius: 60px;
   }
 
   .list-contant{
     width: 85%;
     background-color: #f4f4f4;
     /* background: conic-gradient(from 180deg at 50% 50%, #DBC8EB 0deg, rgba(152, 106, 210, 0.398102) 141.07deg, rgba(234, 194, 218, 0.58) 20.13deg, rgba(204, 225, 236, 0.25) 30deg, #DBC8EB 360deg); */
     background-position:center;
     background-size: cover;
     margin-left: 20px;
   }
 
   .list-head{
     display: flex;
     justify-content: space-between;
     padding: 10px;
     color: #000;
   }
   .btn_live{
     display: flex;
     align-items: center;
 }
 
 .btn_live span{
    margin-left: 5px;
    /* margin-top: -17px; */
  
 }
 
 .list-des{
     padding: 7px;
     color: rgb(38, 38, 39);
     width: 90%;
    font-size: 14px;
 }
 .list-social{
     display: flex;
     
 }
 .list-bottom{
     display: flex;
     justify-content: center;
     bottom: 0;
     width: 85%;
     margin-left: 8px;
 }
 .op{
     display: flex;
     justify-content: center;
 }
 .op img{
     width: 15px;
     border-radius: 60px;
 }
 
 .list-bottom ul{
     display: flex;
     justify-content: space-between;
    
 }
 .list-bottom li{
     margin: 5px;
    
 }
 
 .list-market,.list-num{
     color: rgb(0, 0, 63);
     font-size: 13px;
     font-weight: 500;
 }
 
 
 .list-bottom .fa-facebook-f{
 color: #232ecf;
 }
 .list-bottom .fa-github{
     color: #6b6b6b;
     }
     .list-bottom .fa-twitter{
         color: #127df7;
         }
 .list-right{
     display: flex;
     width: 12%;
     justify-content: flex-end;
 }
 
 .list-right .btn_live{
     color: green;
 }
 
 @keyframes live {
   0% {
     transform: scale(1,1);
   }
   100% {
     transform: scale(3.5,3.5);
     background-color: green;
   }
 }
 
 
 
 .frst_prt-three ul {
 display: flex;
 list-style: none;
 }
 
 .frst_prt-three ul .fa-facebook-f{
     color: rgb(32, 32, 112);
     filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2));
     }
 
     .frst_prt-three ul .fa-twitter{
         color: rgb(34, 122, 173);
         filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2));
         }
 
         .frst_prt-three ul .fa-github{
             filter: drop-shadow(0 0.2rem 0.25rem rgba(3, 0, 0, 0.2));
         }
 
 
    
 
 .frst_prt-three ul li{
     margin: 5px;
     }
 
 .frst_prt-three{
     display: flex;
     justify-content: center;
 }
 
 .pro_banner{
     height: 250px;
     width: 100%;
     background: url('../images/project-banner-3.avif');
     background-repeat: no-repeat;
     background-position: center;
     background-size: cover;
     display: flex;
     box-shadow: -1px 13px 24px -15px rgba(245,193,245,1);
     align-items: center;
     justify-content: space-evenly;
 }
 
 .box{
     border-radius: 24px;
     background: transparent;
    display: grid;
     justify-content: center;
    padding-top: 10px;
    align-items: center;
     text-align: center;
     width: 30%;
 height: 170px;
 background-color: #f4f4f44b;
 box-shadow: 0  17px 10px -10px rgba(167, 166, 166, 0.4);
 border: 2px solid rgba(255, 255, 255, 0.219);
 }
 
 .pro_banner .pros img{
 height: 50px;
 width: 50px;
 }
 
 .paras{
 font-family: serif;
 font-size: larger;
 font-weight: 600;
 }
 
 .value{
     font-family: serif;
     font-size: larger; 
 }

 .sorty{
    display: none;
 }
 
 @media screen and (max-width: 786px) {
     .fils{
         width: 80%;
         border-right: none;
         position: absolute;
         height: auto;
         justify-content: center;
         /* background-color: white; */
         margin-top: 50px;
        z-index: 999;
        display: none;
         /* align-items: center; */
         -webkit-animation: scale-up-hor-left 1.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
             animation: scale-up-hor-left 1.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
           
         
     }
 
     @-webkit-keyframes scale-up-hor-left {
         0% {
           -webkit-transform: scaleX(0.4);
                   transform: scaleX(0.4);
           -webkit-transform-origin: 0% 0%;
                   transform-origin: 0% 0%;
         }
         100% {
           -webkit-transform: scaleX(1);
                   transform: scaleX(1);
           -webkit-transform-origin: 0% 0%;
                   transform-origin: 0% 0%;
         }
       }
       @keyframes scale-up-hor-left {
         0% {
           -webkit-transform: scaleX(0.4);
                   transform: scaleX(0.4);
           -webkit-transform-origin: 0% 0%;
                   transform-origin: 0% 0%;
         }
         100% {
           -webkit-transform: scaleX(1);
                   transform: scaleX(1);
           -webkit-transform-origin: 0% 0%;
                   transform-origin: 0% 0%;
         }
       }
       
       
     .fils.aps{
         display: initial;
     }

     .con{
        padding: 5px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    
        justify-content: center;
       /* justify-self: flex-start; */
        /* height: 130vh; */
            /* overflow: auto; */
    }
 
     .containi{
         width: 100%;
         color: rgb(211, 211, 211);
     }
     .side-b{
         display: flex;
         padding-left: 15px;
         align-items: center;
         color: #1a1919;
         font-weight: 600;
     }
     .side-b span{
      font-size: 10px;
      color: #000;
    }
     .side-b i{
        font-size: 25px;
        background-color: transparent;
        position: relative;
        margin-right: 10px;
     }
     .filters{
     margin-top: 10px;
     width: 90%;
     
   border-radius: 15px;
   overflow: auto;
    /* overflow: auto; */
    background: rgb(145, 145, 145);
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.55);
     -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.55);
     box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
 }


 .grid{
    width: 20%;
    justify-content: center;
    display: flex;
}

.grid i{
  background-color: transparent;
}
.grid img{
    width: 100px;
  }
.grid div{
    margin: 5px;
    width: 80px;
    height: 30px;
    color: #6b6b6b;
    border-radius: 5px;
    padding: 20px 25px#645074;
    background-color: transparent;
    font-weight: 400;
    
    cursor: pointer;
    transition: all 0.3s ease;
   
   justify-content: center;
  display: flex;
  align-items: center;
     box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
     7px 7px 20px 0px rgba(0,0,0,.1),
     4px 4px 5px 0px rgba(0,0,0,.1);
    outline: none;
}
.fil-icon{
  filter: drop-shadow(2px 2px 1px gray);
}

.fil-icon:active{
    filter: drop-shadow(0px 0px 0px gray);
  }
.lis{
    display: flex;
    /* margin: auto; */
    /* border-radius: 15px; */
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.02),
    5px 2px 2px rgba(0, 0, 0, 0.028), 1px 5.1px 5.1px rgba(0, 0, 0, 0.035),
    0 5px 2.1px rgba(0, 0, 0, 0.042), 1px 3.7px 2.8px rgba(0, 0, 0, 0.05),
    0 1px 1px rgba(0, 0, 0, 0.07);
    background-color: #c50000;
    border-radius: 20px;
    /* height: 100px; */
    
  }

  .hs{
    display: none;
}

.sorty{
    display: initial;
    font-size: larger;
}

.sort{
    justify-content: center;
    width: 150px;
    display: flex;
}
     }
 

      @media screen and (max-width: 1650px)
     {
        .carda{
            margin:23px;
             width: 200px;
             height: 200px;
             border-radius: 20px;
             overflow: hidden;
             position: relative;
             cursor: pointer;
             box-shadow: 0.1px 0.2px 5px 0px rgba(139, 139, 139, 0.75);
           
         }
     }

     @media screen and (max-width: 1320px)
     {
        .carda{
            margin:20px;
             width: 200px;
             height: 200px;
             border-radius: 20px;
             overflow: hidden;
             position: relative;
             cursor: pointer;
             box-shadow: 0.1px 0.2px 5px 0px rgba(139, 139, 139, 0.75);
           
         }
     }

     @media screen and (max-width: 1290px)
     {
        .carda{
            margin:18px;
             width: 200px;
             height: 200px;
             border-radius: 20px;
             overflow: hidden;
             position: relative;
             cursor: pointer;
             box-shadow: 0.1px 0.2px 5px 0px rgba(139, 139, 139, 0.75);
           
         }
     }

     @media screen and (max-width: 1260px)
     {
        .carda{
            margin:15px;
             width: 200px;
             height: 200px;
             border-radius: 20px;
             overflow: hidden;
             position: relative;
             cursor: pointer;
             box-shadow: 0.1px 0.2px 5px 0px rgba(139, 139, 139, 0.75);
           
         }
     }

     @media screen and (max-width: 1268px)
     {
        .carda{
            margin:15px;
             width: 200px;
             height: 200px;
             border-radius: 20px;
             overflow: hidden;
             position: relative;
             cursor: pointer;
             box-shadow: 0.1px 0.2px 5px 0px rgba(139, 139, 139, 0.75);
           
         }
     }

     @media screen and (max-width: 1238px)
     {
        .carda{
            margin:10px;
             width: 200px;
             height: 200px;
             border-radius: 20px;
             overflow: hidden;
             position: relative;
             cursor: pointer;
             box-shadow: 0.1px 0.2px 5px 0px rgba(139, 139, 139, 0.75);
           
         }
     }

     @media screen and (max-width: 1166px)
     {
        .carda{
            margin:5px;
             width: 200px;
             height: 200px;
             border-radius: 20px;
             overflow: hidden;
             position: relative;
             cursor: pointer;
             box-shadow: 0.1px 0.2px 5px 0px rgba(139, 139, 139, 0.75);
           
         }
     } 
